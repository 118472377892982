/* Dummy Data: You can change this as per your demand */

export const names: string[] = [
  "Water - Spring 1.5lit",
  "Crab - Soft Shell",
  "Wine - Domaine Boyar Royal",
  "Sea Bass - Whole",
  "Mix - Cocktail Ice Cream",
  "Cheese - Cheddar, Mild",
  "Bread - English Muffin",
  "Puree - Blackcurrant",
  "Maintenance Removal Charge",
  "Coffee - Espresso",
  "Pork - Smoked Back Bacon",
  "Chocolate Bar - Oh Henry",
  "Dry Ice",
  "Pasta - Canelloni",
  "Eggroll",
  "Wine - Chateau Timberlay",
  "Wine - White, Ej",
  "Tea Leaves - Oolong",
  "Wine - Champagne Brut Veuve",
  "Cookies Oatmeal Raisin",
  "Egg Patty Fried",
  "Ice Cream - Fudge Bars",
  "Onion - Dried",
  "Foil - 4oz Custard Cup",
  "Tendrils - Baby Pea, Organic",
  "Duck - Breast",
  "Salmon - Smoked, Sliced",
  "Quail - Whole, Bone - In",
  "Table Cloth 62x114 White",
  "Bar Mix - Lemon",
  "Bok Choy - Baby",
  "Chips Potato Salt Vinegar 43g",
  "Cookie Dough - Peanut Butter",
  "Beef - Kindney, Whole",
  "Muffin Chocolate Individual Wrap",
  "Coffee - Decafenated",
  "Wine - Jaboulet Cotes Du Rhone",
  "Rum - Dark, Bacardi, Black",
  "Truffle Shells - Semi - Sweet",
  "Dried Apple",
  "Crackers - Trio",
  "Ice Cream - Chocolate",
  "Pasta - Fettuccine, Dry",
  "Asparagus - White, Canned",
  "Irish Cream - Baileys",
  "Southern Comfort",
  "Pork - Backs - Boneless",
  "Pork Ham Prager",
  "Lettuce - Iceberg",
  "Spinach - Spinach Leaf",
  "Pasta - Cheese / Spinach Bauletti",
  "Beets - Candy Cane, Organic",
  "Pepper - Sorrano",
  "Longos - Burritos",
  "Sugar - Sweet N Low, Individual",
  "Water - Green Tea Refresher",
  "Sprouts Dikon",
  "Beef - Rib Roast, Cap On",
  "Juice - Propel Sport",
  "Butter Ripple - Phillips",
  "Veal - Ground",
  "Sauce - Thousand Island",
  "Veal - Slab Bacon",
  "Pork - Side Ribs",
  "Mushroom - Trumpet, Dry",
  "Wine - Prem Select Charddonany",
  "Celery Root",
  "Coconut - Shredded, Unsweet",
  "Amarula Cream",
  "Cheese - Brie, Triple Creme",
  "Coffee Cup 12oz 5342cd",
  "Artichoke - Bottom, Canned",
  "Tomatoes - Heirloom",
  "Island Oasis - Lemonade",
  "Cheese Cloth No 100",
  "Wine - Pinot Noir Mondavi Coastal",
  "Bread - English Muffin",
  "Vaccum Bag - 14x20",
  "Mustard - Individual Pkg",
  "Lobster - Canned Premium",
  "Fish - Bones",
  "Milk - 1%",
  "Chicken Thigh - Bone Out",
  "Sesame Seed Black",
  "Devonshire Cream",
  "Rice Paper",
  "Lettuce - Lambs Mash",
  "Squid - Breaded",
  "Isomalt",
  "Tea - Grapefruit Green Tea",
  "Sauce - Soya, Light",
  "Gelatine Leaves - Envelopes",
  "Flower - Dish Garden",
  "Wine - Vouvray Cuvee Domaine",
  "Veal - Insides Provini",
  "Fiddlehead - Frozen",
  "Veal - Eye Of Round",
  "Prunes - Pitted",
  "Pasta - Orecchiette",
  "Eggplant - Asian",
];

// Material UI only supports string type in autocomplete options
export const count: string[] = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
];
